import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import LineSeparator from "../../components/LineSeparator";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageBanner from "../../components/PageBanner";
import Layouts from "../../layouts/Layouts";
import useApprovedOffers from "../../utils/useApprovedOffers";
import "./style.css";

// import NYBGimg from "../../assets/images/new-year/event-bg.png";
// import NYENimg from "../../assets/images/new-year/event-en.png";

// import offerBG1 from "../../assets/images/new-year/offer-1-bg.png";
// import offerEN1 from "../../assets/images/new-year/offer-1-en.png";
// import offerBG2 from "../../assets/images/new-year/offer-2-bg.png";
// import offerEN2 from "../../assets/images/new-year/offer-2-en.png";
// import offerBG3 from "../../assets/images/new-year/offer-3-bg.png";
// import offerEN3 from "../../assets/images/new-year/offer-3-en.png";

// import nyOfferBG from "../../assets/images/new-year/ny-offer-bg.pdf";
// import nyOfferEN from "../../assets/images/new-year/ny-offer-en.pdf";

export default function OffersPage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const { offers, loading } = useApprovedOffers(language);
  const text = "text" + language;
  const title = "title" + language;

  return (
    <Layouts>
      <PageBanner pageName={t("offers")} />
      {/* 
      <div className="offers-container">
        <Fragment>
          <div className="single-offer">
            <div className="offer-content">
              // <div className="offer-title">{t("new_year_offer")}</div>
              <img
                src={i18n.language === "bg" ? offerBG1 : offerEN1}
                alt=""
                onClick={() => {
                  window.open(i18n.language === "bg" ? nyOfferBG : nyOfferEN);
                }}
              />
              <img
                src={i18n.language === "bg" ? offerBG2 : offerEN2}
                alt=""
                onClick={() => {
                  window.open(i18n.language === "bg" ? nyOfferBG : nyOfferEN);
                }}
              />
              <img
                src={i18n.language === "bg" ? offerBG3 : offerEN3}
                alt=""
                onClick={() => {
                  window.open(i18n.language === "bg" ? nyOfferBG : nyOfferEN);
                }}
              />
            </div>
          </div>
          <LineSeparator />
        </Fragment>
      </div> */}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="offers-container">
          {offers && offers.length ? (
            offers.map((offer, index) => {
              return offer[text] !== "" && offer[title] !== "" ? (
                <Fragment key={index}>
                  <div className="single-offer">
                    <img src={offer.image} alt="" />
                    <div className="offer-content">
                      <div className="offer-title">{offer[title]}</div>
                      <div dangerouslySetInnerHTML={{ __html: offer[text] }} />
                    </div>
                  </div>
                  {index !== offers.length - 1 && <LineSeparator />}
                </Fragment>
              ) : (
                <></>
              );
            })
          ) : (
            <div
              className="no-available-offers"
              style={{ alignSelf: "center" }}
            >
              {t("no-offers")}
            </div>
          )}
        </div>
      )}
    </Layouts>
  );
}
