import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Layouts from "../layouts/Layouts";
import { bannerSlider } from "../sliderProps";
import { useTranslation } from "react-i18next";
import LineSeparator from "../components/LineSeparator/index";
import AvailabilityPicker from "../components/AvailabilityPicker";
import { Icon } from "../components/Icon/Icon";

// import heroNYBG from "../assets/images/new-year/event-bg.png";
// import heroNYEN from "../assets/images/new-year/event-en.png";
import hero1 from "../assets/images/hero1.jpg";
import hero2 from "../assets/images/hero2.jpg";
import hero3 from "../assets/images/hero3.jpg";
import hero4 from "../assets/images/hero4.jpg";
import hero5 from "../assets/images/hero5.jpg";

import restaurant from "../assets/images/restaurant.jpg";
// import azzurro from "../assets/images/azzurro-new.jpg";
import asianSamba from "../assets/images/asian-samba-new.jpg";
// import confectionery from "../assets/images/confectionery.jpg";
import loungeBar from "../assets/images/lounge-bar-4.jpg";
// import swimUp from "../assets/images/swim-up-bar-2.jpg";

// Template Photos
import shape3 from "../assets/images/shape/shape-3.png";

import "../assets/css/homepage.css";
import { DisplayReviews } from "./ReviewsPage/ReviewsPage";
import RoomsHomeView from "../components/RoomsHomeView";

const Index = () => {
  const [grillWhite, setGrillWhite] = useState(false);
  const [pianoWhite, setPianoWhite] = useState(false);
  const [bicycleWhite, setBicycleWhite] = useState(false);
  const [vaultWhite, setVaultWhite] = useState(false);
  const [poolTableWhite, setPoolTableWhite] = useState(false);
  // const [amoreWhite, setAmoreWhite] = useState(false);

  const { t } = useTranslation();
  return (
    <Layouts extraCls={"ex"} headernumber>
      {/* Banner Section */}
      <section className="banner-section">
        <div className="shape">
          <img src={shape3} alt="" />
        </div>
        <div className="big-title">Admiral</div>
        <Swiper {...bannerSlider} className="swiper-container banner-slider">
          <div className="swiper-wrapper">
            {/* <SwiperSlide className="swiper-slide">
              <div className="content-outer">
                <div className="content-box">
                  <div className="inner"></div>
                  <div className="image-box">
                    <div className="image">
                      <img
                        src={i18n.language === "bg" ? heroNYBG : heroNYEN}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide className="swiper-slide">
              <div className="content-outer">
                <div className="content-box">
                  <div className="inner"></div>
                  <div className="image-box">
                    <div className="image">
                      <img src={hero1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="content-outer">
                <div className="content-box">
                  <div className="inner"></div>
                  <div className="image-box">
                    <div className="image">
                      <img src={hero2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="content-outer">
                <div className="content-box">
                  <div className="inner"></div>
                  <div className="image-box">
                    <div className="image">
                      <img src={hero3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="content-outer">
                <div className="content-box">
                  <div className="inner"></div>
                  <div className="image-box">
                    <div className="image">
                      <img src={hero4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="content-outer">
                <div className="content-box">
                  <div className="inner"></div>
                  <div className="image-box">
                    <div className="image">
                      <img src={hero5} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
        <div className="banner-slider-nav">
          <div className="banner-slider-control banner-slider-button-prev">
            <span>
              <i className="fas fa-arrow-right" />
            </span>
          </div>
          <div className="banner-slider-control banner-slider-button-next">
            <span>
              <i className="fas fa-arrow-right" />
            </span>{" "}
          </div>
        </div>
      </section>
      {/* End Bnner Section */}
      {/* Check availability */}
      <AvailabilityPicker />
      {/* section one */}
      <section className="section-one">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="block-two text-center">
                {/* <div className="image mb-10">
                  <img src={iconImage2} alt="" />
                </div> */}
                <h2 className="sec-title">
                  {t("welcome")}{" "}
                  <span className="theme-color">{t("hotel-admiral")}</span>{" "}
                </h2>
                <div className="text-two">{t("one-sentence-about")}</div>
                <div className="text-two">{t("one-sentence-about-2")}</div>
                <div className="bottom-content justify-content-center">
                  <Link to="/about" className="theme-btn btn-style-one dark">
                    <span>{t("more-about-us")}</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="block-one">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="image mb-30 slogan index__main-video">
                      {/* <img src={admiral2} alt="" /> */}
                      {/* <iframe
                        title="vimeo-player"
                        src="https://player.vimeo.com/video/733635604?h=baa2033c9b"
                        frameborder="0"
                        allowfullscreen
                      ></iframe> */}
                      <iframe
                        // src="https://www.youtube.com/embed/PaOM3w-1tcU?si=aMEzDSDTB581LDh9&rel=0"
                        src="https://player.vimeo.com/video/733635604?h=baa2033c9b"
                        title={t("hotel-admiral")}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section two */}
      <section className="section-two">
        <div className="auto-container">
          {/* <div className="sub-title text-center">
            New Offers <span>-25%</span>
          </div> */}
          <h2 className="sec-title text-center text-light">{t("dining")}</h2>
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image box-shadow">
                  <img src={restaurant} alt="" />
                </div>
                <div className="inner-box">
                  {/* <div className="title">Upto 40% Off</div> */}
                  <div className="text title-with-bg">{t("restaurant")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image box-shadow">
                  <iframe
                    src="https://www.youtube.com/embed/3ezU-SAN8_E?mute=1&autoplay=1&loop=1&playlist=3ezU-SAN8_E"
                    style={{ width: "100%", minHeight: "245px" }}
                    title={t("azzurro")}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="inner-box">
                  {/* <div className="title">For This Week</div> */}
                  <div className="text title-with-bg">{t("azzurro")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image box-shadow">
                  <img src={asianSamba} alt="" />
                </div>
                <div className="inner-box">
                  {/* <div className="title">Free Membership</div> */}
                  <div className="text title-with-bg">{t("asian-samba")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image box-shadow">
                  <iframe
                    src="https://www.youtube.com/embed/uGDjhz5VPWo?start=3&autoplay=1&mute=1&loop=1&playlist=uGDjhz5VPWo"
                    style={{ width: "100%", minHeight: "245px" }}
                    // src="https://player.vimeo.com/video/733635604?h=baa2033c9b"
                    title={t("confectionery")}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                  {/* <img src={confectionery} alt="" /> */}
                </div>
                <div className="inner-box">
                  {/* <div className="title">Upto 40% Off</div> */}
                  <div className="text title-with-bg">{t("confectionery")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image box-shadow">
                  <img src={loungeBar} alt="" />
                </div>
                <div className="inner-box">
                  {/* <div className="title">For This Week</div> */}
                  <div className="text title-with-bg">{t("bar-mezzanine")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image box-shadow">
                  <iframe
                    src="https://www.youtube.com/embed/lTJ47MrWF28?mute=1&autoplay=1&loop=1&playlist=lTJ47MrWF28"
                    style={{ width: "100%", minHeight: "245px" }}
                    title={t("azzurro")}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="inner-box">
                  {/* <div className="title">Free Membership</div> */}
                  <div className="text title-with-bg">{t("swim-up")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section four */}
      <section className="section-four">
        <div className="auto-container">
          <LineSeparator style={{ marginBottom: "50px" }} />
          {/* <div className="sub-title text-center">Gallary</div> */}
          <RoomsHomeView />
        </div>
      </section>
      {/* section five */}
      {/* <section className="section-five">
        <div className="auto-container">
          <div className="block-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="block-five">
                  <div className="image">
                    <img src={image7PNG} alt="" />
                  </div>
                  <div className="inner-box">
                    <div className="time">Time &amp; Shedule</div>
                    <h3>Opening Hours</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block-five">
                  <div className="icon">
                    <i className="flaticon-tray" />
                    <span>Dinner: 4:30pm – 10:30pm</span>
                  </div>
                  <div className="icon">
                    <i className="flaticon-martini" />
                    <span>Bar (Drinks + Bites): 4:30pm – 12am</span>
                  </div>
                  <div className="icon">
                    <i className="flaticon-bath-tub" />{" "}
                    <span>Cocktails &amp; Whiskey: Until 2am</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* section six */}
      <section className="section-six">
        <div className="auto-container">
          <h2 className="sec-title text-center">{t("services")}</h2>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-breakfast" />
                </div>
                <h4>{t("ultra-all-inclusive")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-massage" />
                </div>
                <h4>{t("spa")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-cheers" />
                </div>
                <h4>{t("lobby-bar")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-pool" />
                </div>
                <h4>{t("outdoor-pool")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-car" />
                </div>
                <h4>{t("parking")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-resort" />
                </div>
                <h4>{t("own-beach")}</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-tray" />
                </div>
                <h4>{t("restaurants")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-weights" />
                </div>
                <h4>{t("fitness")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-dollar" />
                </div>
                <h4>{t("currency-exchange")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-conference" />
                </div>
                <h4>{t("conference")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setPianoWhite(true)}
                onMouseLeave={() => setPianoWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={pianoWhite ? "piano-white" : "piano"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("piano-bar-service")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setBicycleWhite(true)}
                onMouseLeave={() => setBicycleWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={bicycleWhite ? "bicycle-white" : "bicycle"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("rent-bicycle-service")}</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setGrillWhite(true)}
                onMouseLeave={() => setGrillWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={grillWhite ? "grill-white" : "grill"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("grill-service")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-laundry" />
                </div>
                <h4>{t("laundry")}</h4>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setAmoreWhite(true)}
                onMouseLeave={() => setAmoreWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={amoreWhite ? "amore-white" : "amore"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("amore-service")}</h4>
              </div>
            </div> */}
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-wifi-signal" />
                </div>
                <h4>{t("wifi")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setVaultWhite(true)}
                onMouseLeave={() => setVaultWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={vaultWhite ? "vault-white" : "vault"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("vault")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setPoolTableWhite(true)}
                onMouseLeave={() => setPoolTableWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={poolTableWhite ? "8-ball-white" : "8-ball"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("pool-table")}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section seven */}
      {/* <section className="section-seven">
        <h4 className="d-none">heading</h4>
        <div className="auto-container">
          <div className="block-seven">
            <div className="image">
              <img src={imageVideo} alt="" />
            </div>
            <div className="video-btn">
              <a
                href="https://www.youtube.com/watch?v=nfP5N9Yc72A&t=28s"
                className="overlay-link play-now ripple"
                data-fancybox="gallery"
                data-caption=""
              >
                <i className="fas fa-play" />
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/* testimonials section */}
      <section className="testimonials-section">
        <div className="auto-container">
          <div className="top-content">
            <div className="sec-title text-center mb-30">
              {/* <div className="sub-title">Testimonials</div> */}
              <h2 className="sec-title">{t("clients-say")}</h2>
            </div>
          </div>
          <DisplayReviews />
        </div>
      </section>
    </Layouts>
  );
};
export default Index;
